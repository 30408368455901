import { Box, Button, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';

const DEMAND_APP = process.env.REACT_APP_DEMAND_APP;
export function ProductPreview() {
  const { productId } = useParams();

  const handleClickCloseTab = () => {
    window.open('', '_self');
    window.close();
  };

  return (
    <>
      <Box textAlign="center" mt={2}>
        <Box
          bgcolor="#F4F4F6"
          display="inline-block"
          width="100%"
          sx={{ padding: '2rem' }}
        >
          <Typography variant="body1">プレビュー画面です</Typography>
        </Box>
        <Box sx={{ mb: '1.5rem', mt: '1.5rem' }}>
          <Button
            variant="contained"
            color="primary"
            sx={{
              borderRadius: '10px',
              height: '3rem',
              width: '9.5rem',
            }}
            onClick={handleClickCloseTab}
          >
            閉じる
          </Button>
        </Box>
      </Box>
      <iframe
        src={`${DEMAND_APP}/product-preview/${productId}`}
        title="プレビュー"
        style={{
          border: '0',
          height: 'calc(100vh - 80px)',
          maxWidth: '100%',
          width: '100%',
        }}
      />
    </>
  );
}
