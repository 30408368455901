import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { GridColDef, GridPaginationModel } from '@mui/x-data-grid';
import { ReactElement, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useRecoilState, useRecoilValue } from 'recoil';

import {
  getUserChatTopicListWithFilter,
  createNewTopic,
  postChatMessage,
} from '@app/adapter/chat-service';
import {
  getOrganizationById,
  updateOrganizationInfo,
} from '@app/adapter/organization-service';
import {
  INITIAL_PAGINATE_MODEL,
  StandardStyleDataGrid,
} from '@app/components/Shared/StandardStyleDataGrid';
import { loggedInUserState } from '@app/domain/app';
import { organization } from '@app/domain/organization';
import { ScoutInputForm, ScoutInputFormData } from '@app/schemas/user';
import { MessageTypeId } from '@app/types/chats';
import { UserScout } from '@app/types/user';
import { isError } from '@app/utils/error';
import { getAge } from '@app/utils/index';
import { getScoutPossibleCount } from '@app/utils/organization';
import { useSetSnackbar } from '@app/utils/useSetSnackbar';

interface ScoutInputModalProps {
  isOpen: boolean;
  items: UserScout[];
  onClose: (items?: UserScout[]) => void;
}

export function ScoutInputModal({
  isOpen,
  items,
  onClose,
}: ScoutInputModalProps): ReactElement {
  const setSnackbar = useSetSnackbar();
  const loggedInUser = useRecoilValue(loggedInUserState);
  const [organizationState, setOrganizationState] =
    useRecoilState(organization);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPaginationModel, setCurrentPaginationModel] =
    useState<GridPaginationModel>(INITIAL_PAGINATE_MODEL);
  const columns: Array<GridColDef> = [
    {
      field: 'age',
      headerName: '年齢・性別',
      renderCell: (params) => (
        <Typography variant="body2">
          {getAge(params.row.customFields?.birthday) || '-'}歳・
          {params.row.customFields.gender}
        </Typography>
      ),
      sortable: false,
      width: 140,
    },
    {
      field: 'clinicalDepartments',
      headerName: '専門領域',
      renderCell: (params) => (
        <Typography
          variant="body2"
          sx={{ whiteSpace: 'normal', wordBreak: 'break-word' }}
        >
          {params.row.customFields?.clinicalDepartments?.join('・') || '-'}
        </Typography>
      ),
      sortable: false,
      width: 300,
    },
    {
      field: 'area',
      headerName: '地域',
      renderCell: (params) => (
        <Typography variant="body2">
          {params.row.addressLine1 || '-'}
        </Typography>
      ),
      sortable: false,
      width: 76,
    },
  ];

  const {
    control,
    formState: { isValid },
    handleSubmit,
    reset,
  } = useForm<ScoutInputFormData>({
    defaultValues: ScoutInputForm.defaultValues,
    mode: 'all',
    resolver: ScoutInputForm.resolver,
  });

  const handleClose = () => {
    onClose();
    reset();
  };

  const handleSubmitSendScout = async (inputData: ScoutInputFormData) => {
    if (!loggedInUser) return;
    setIsLoading(true);
    try {
      const { data: latestOrganization } = await getOrganizationById(
        organizationState.id
      );
      if (getScoutPossibleCount(latestOrganization) < items.length) {
        throw new Error('スカウト可能数が不足しています');
      }

      const updatedItems = await Promise.all(
        items.map(async (item) => {
          const {
            data: { value: topics },
          } = await getUserChatTopicListWithFilter(loggedInUser.id, {
            filter: {
              ownerIds: [item.id],
            },
            pageSize: 1,
          });
          const topic =
            topics[0] ||
            (
              await createNewTopic(
                loggedInUser.id,
                item.id,
                organizationState.name
              )
            ).data;

          await postChatMessage(loggedInUser.id, {
            content: inputData.message,
            receiptId: item.id,
            title: 'scout',
            topicId: topic.id,
            typeId: MessageTypeId.MESSAGE,
          });
          return item;
        })
      );

      const { data: updatedOrganization } = await updateOrganizationInfo(
        organizationState.id,
        {
          customFields: {
            ...latestOrganization.customFields,
            scoutCount:
              (latestOrganization.customFields?.scoutCount || 0) + items.length,
          },
        }
      );
      setOrganizationState(updatedOrganization);
      setSnackbar(true, 'スカウトメッセージを送りました', 'info');
      onClose(updatedItems);
      reset();
    } catch (e) {
      setSnackbar(true, 'スカウトに失敗しました', 'error');
      if (isError(e)) {
        console.error(e.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog open={isOpen} onClose={() => onClose()} maxWidth="sm" fullWidth>
      <DialogTitle>常勤・スカウト</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <Typography variant="body2">
            下記のドクターにスカウトを送ります
          </Typography>
          <StandardStyleDataGrid
            paginationModel={currentPaginationModel}
            columns={columns}
            rows={items}
            rowCount={items.length}
            disableColumnMenu
            disableRowSelectionOnClick
            hideFooter
            onPaginationModelChange={setCurrentPaginationModel}
            sx={{
              '.MuiDataGrid-cell': {
                cursor: 'inherit',
              },
              mb: 1,
            }}
          />
          <Box>
            <Typography variant="body2">
              メッセージ
              <Typography component="span" color="error">
                *
              </Typography>
            </Typography>
            <Controller
              name="message"
              control={control}
              rules={{ required: true }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  error={!!error}
                  helperText={error?.message}
                  margin="dense"
                  placeholder="メッセージを入力"
                  rows={12}
                  multiline
                  fullWidth
                />
              )}
            />
          </Box>
          <Stack direction="row" spacing={1} justifyContent="end">
            <Button
              variant="outlined"
              color="secondary"
              size="small"
              onClick={handleClose}
              sx={{ minWidth: '120px' }}
            >
              閉じる
            </Button>
            <LoadingButton
              variant="contained"
              color="primary"
              size="small"
              disabled={!isValid}
              loading={isLoading}
              onClick={handleSubmit(handleSubmitSendScout)}
              sx={{ minWidth: '120px' }}
            >
              スカウトを送る
            </LoadingButton>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
