import {
  PersonOutline as PersonOutlineIcon,
  StarRounded as StarRoundedIcon,
} from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Avatar,
  Button,
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  MenuItem,
  Paper,
  Rating,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import { GridColDef, GridPaginationModel } from '@mui/x-data-grid';
import { ReactElement, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useRecoilState, useRecoilValue } from 'recoil';

import { getOrganizationById } from '@app/adapter/organization-service';
import { getScoutUsers } from '@app/adapter/user-service';
import { ScoutInputModal } from '@app/components/Scout/ScoutInputModal';
import { PageTitle } from '@app/components/Shared/PageTitle';
import { StandardStyleDataGrid } from '@app/components/Shared/StandardStyleDataGrid';
import {
  attributesByClinicalDepartmentSelector,
  locationsByPrefectureSelector,
} from '@app/domain/catalog';
import { organization } from '@app/domain/organization';
import { ScoutSearchForm, ScoutSearchFormData } from '@app/schemas/user';
import { UserScout, JobChange } from '@app/types/user';
import { getAge } from '@app/utils';
import { isError } from '@app/utils/error';
import { dateFormatJp } from '@app/utils/format';
import { getScoutPossibleCount } from '@app/utils/organization';
import { useSetSnackbar } from '@app/utils/useSetSnackbar';

const SEARCH_JOB_CHANGE_OPTIONS = [
  { label: '積極的に聞きたい', value: JobChange.ACTIVE },
  { label: 'いい条件があれば聞きたい', value: JobChange.CONDITIONS_MATCH },
  { label: 'ー（今は考えていない）', value: JobChange.NOT_THINKING },
];
const SEARCH_REVIEW_OPTIONS = [
  { label: '評価あり', value: '1' },
  { label: '総合評価★4以上', value: '4' },
  { label: '総合評価★3以上', value: '3' },
];
const SEARCH_WORK_RESULT_OPTIONS = [
  { label: '勤務あり', value: '1' },
  { label: '3回以上勤務あり', value: '3' },
  { label: '勤務無し', value: '0' },
];
const SEARCH_GENDER_OPTIONS = [
  { label: '女性', value: '女性' },
  { label: '男性', value: '男性' },
];
const SEARCH_AGE_OPTIONS = [
  { label: '20代', value: '20 29' },
  { label: '30代', value: '30 39' },
  { label: '40代', value: '40 49' },
  { label: '50代', value: '50 59' },
  { label: '60以上', value: '60' },
];

export function Scouts(): ReactElement {
  const setSnackbar = useSetSnackbar();
  const attributesByClinicalDepartment = useRecoilValue(
    attributesByClinicalDepartmentSelector
  );
  const prefectures = useRecoilValue(locationsByPrefectureSelector);
  const [organizationState, setOrganizationState] =
    useRecoilState(organization);
  const [isLoading, setIsLoading] = useState(false);
  const [isScoutInputModal, setIsScoutInputModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState<UserScout>();
  const columns: Array<GridColDef> = [
    {
      field: 'age',
      headerName: '年齢・性別',
      renderCell: (params) => (
        <Stack direction="row" spacing={1} alignItems="center">
          <Avatar
            sx={{
              backgroundColor: 'lightblue',
              height: 32,
              width: 32,
            }}
          >
            <PersonOutlineIcon />
          </Avatar>
          <Typography variant="body2">
            {getAge(params.row.customFields?.birthday) || '-'}歳・
            {params.row.customFields.gender}
          </Typography>
        </Stack>
      ),
      sortable: false,
      width: 150,
    },
    {
      field: 'clinicalDepartments',
      flex: 1,
      headerName: '専門領域',
      minWidth: 180,
      renderCell: (params) => (
        <Typography
          variant="body2"
          sx={{ whiteSpace: 'normal', wordBreak: 'break-word' }}
        >
          {params.row.customFields?.clinicalDepartments?.join('・') || '-'}
        </Typography>
      ),
      sortable: false,
    },
    {
      field: 'area',
      headerName: '地域',
      renderCell: (params) => (
        <Typography variant="body2">
          {params.row.addressLine1 || '-'}
        </Typography>
      ),
      sortable: false,
      width: 76,
    },
    {
      field: 'jobChange',
      headerName: '転職意欲',
      renderCell: (params) => (
        <Typography variant="body2" component="div" textAlign="center">
          {params.row.customFields?.jobChange === JobChange.ACTIVE ? (
            '積極的'
          ) : params.row.customFields?.jobChange ===
            JobChange.CONDITIONS_MATCH ? (
            <>
              <div>いい条件</div>
              <div>あれば</div>
            </>
          ) : (
            '-'
          )}
        </Typography>
      ),
      sortable: false,
      width: 76,
    },
    {
      field: 'review',
      headerName: '評価',
      renderCell: (params) => (
        <Stack direction="row" alignItems="center">
          <Typography variant="body2">
            {getRate(params.row.reviewStats?.averageRate)}
          </Typography>
          <Rating
            defaultValue={getRate(params.row.reviewStats?.averageRate)}
            precision={0.1}
            size="small"
            icon={<StarRoundedIcon fontSize="inherit" />}
            emptyIcon={<StarRoundedIcon fontSize="inherit" />}
            readOnly
          />
        </Stack>
      ),
      sortable: false,
      width: 130,
    },
    {
      field: 'lastApplyDate',
      headerName: '最終応募日',
      renderCell: (params) => (
        <Typography variant="body2" component="div">
          {params.row.latestOrderDate ? (
            <>
              <div>{dateFormatJp(params.row.latestOrderDate, 'yyyy年')}</div>
              <div>
                {dateFormatJp(params.row.latestOrderDate, 'M月d日(eee)')}
              </div>
            </>
          ) : (
            '-'
          )}
        </Typography>
      ),
      sortable: false,
      width: 102,
    },
    {
      field: 'scoutDate',
      headerName: 'スカウト送付日',
      renderCell: (params) => (
        <Typography variant="body2" component="div">
          {params.row.scoutedDate ? (
            <>
              <div>{dateFormatJp(params.row.scoutedDate, 'yyyy年')}</div>
              <div>{dateFormatJp(params.row.scoutedDate, 'M月d日(eee)')}</div>
            </>
          ) : (
            '-'
          )}
        </Typography>
      ),
      sortable: false,
      width: 118,
    },
    {
      field: 'actions',
      headerName: '',
      renderCell: (params) => (
        <Button
          variant="outlined"
          size="small"
          color="secondary"
          disabled={isNotScoutPossible}
          onClick={() => handleClickScout(params.row)}
          sx={{ width: 106 }}
        >
          <Typography variant="body2">スカウトを送る</Typography>
        </Button>
      ),
      sortable: false,
      width: 126,
    },
  ];

  const { control, getValues } = useForm<ScoutSearchFormData>({
    defaultValues: ScoutSearchForm.defaultValues,
    mode: 'all',
    resolver: ScoutSearchForm.resolver,
  });

  // Pagination
  const PAGE_SIZE_OPTIONS = [10];
  const INITIAL_PAGINATE_MODEL = {
    page: 0,
    pageSize: PAGE_SIZE_OPTIONS[0],
  };
  const [currentPaginationModel, setCurrentPaginationModel] =
    useState<GridPaginationModel>(INITIAL_PAGINATE_MODEL);
  const [rows, setRows] = useState<Array<UserScout>>([]);
  const [rowTotal, setRowTotal] = useState<number>(
    INITIAL_PAGINATE_MODEL.pageSize
  );

  const getRate = (value?: number): number => {
    if (!value) return 0;
    return Math.floor(value * 10) / 10;
  };

  const handleClickScout = (organization: UserScout) => {
    setSelectedItem(organization);
    setIsScoutInputModal(true);
  };

  const fetchScouts = async () => {
    if (isLoading) return;
    setIsLoading(true);
    try {
      const { page, pageSize } = currentPaginationModel;
      const { data: scoutData } = await getScoutUsers({
        filter: {
          address: getValues('address'),
          age: getValues('age'),
          clinicalDepartment: getValues('clinicalDepartment'),
          gender: getValues('gender'),
          isScouted: getValues('isScouted') ? 1 : 0,
          jobChange: getValues('jobChange'),
          review: getValues('review'),
          workResult: getValues('workResult'),
        },
        skip: Math.ceil(page * pageSize),
        top: pageSize,
      });
      setRows(scoutData.value);
      setRowTotal(scoutData.total);
    } catch (error) {
      if (isError(error)) {
        console.error(error.message);
      }
      setSnackbar(true, 'ユーザー情報の取得に失敗しました', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    void fetchScouts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPaginationModel]);

  // 初回のみ
  useEffect(() => {
    const fetchOrganization = async () => {
      try {
        const { data: orgData } = await getOrganizationById(
          organizationState.id
        );
        setOrganizationState(orgData);
      } catch (error) {
        if (isError(error)) {
          console.error(error.message);
        }
      }
    };
    void fetchOrganization();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const scoutPossibleCount = useMemo(() => {
    return getScoutPossibleCount(organizationState);
  }, [organizationState]);
  const isNotScoutPossible = useMemo(() => {
    return scoutPossibleCount < 1;
  }, [scoutPossibleCount]);

  return (
    <>
      <Stack direction="row" spacing={2} alignItems="center" sx={{ mb: 3 }}>
        <PageTitle title="常勤・スカウト" />
        <Typography variant="body2" sx={{ marginBottom: '-10px !important' }}>
          スカウト数：あと
          <strong>{scoutPossibleCount}</strong>件
        </Typography>
        {isNotScoutPossible && (
          <Typography
            variant="button"
            sx={{
              backgroundColor: '#fff',
              border: '1px solid #C4C4C4',
              borderRadius: '4px',
              color: '#00A3CE',
              lineHeight: '1.5',
              padding: '6px 10px',
            }}
          >
            最初の10人のみ表示しております
            <br />
            他の候補者をさらに検索・スカウトを送付するには、スカウトオプションのお申し込みが必要です。
            <br />
            <a
              href="https://forms.gle/HhcHnK6rWTa9sVbL7"
              target="_blank"
              rel="nofollow noreferrer"
              style={{ color: '#00A3CE' }}
            >
              こちら
            </a>
            からお問い合わせください
          </Typography>
        )}
      </Stack>
      <Card sx={{ mb: 2 }}>
        <Stack spacing={2} p={2}>
          <Stack direction="row" spacing={3}>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography variant="body2">転職意欲</Typography>
              <Controller
                name="jobChange"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <FormControl>
                    <Select
                      {...field}
                      error={!!error}
                      size="small"
                      displayEmpty
                      sx={{ width: 140 }}
                    >
                      <MenuItem value="">すべて</MenuItem>
                      {SEARCH_JOB_CHANGE_OPTIONS.map((option, index) => (
                        <MenuItem key={index} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
            </Stack>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography variant="body2">専門領域</Typography>
              <Controller
                name="clinicalDepartment"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <FormControl>
                    <Select
                      {...field}
                      error={!!error}
                      size="small"
                      multiple
                      displayEmpty
                      renderValue={(selected) => {
                        return selected.length > 0 ? (
                          selected.join(',')
                        ) : (
                          <div>すべて</div>
                        );
                      }}
                      sx={{ width: 150 }}
                    >
                      {attributesByClinicalDepartment.map((option) => (
                        <MenuItem key={option.id} value={option.items[0].value}>
                          {option.items[0].value}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
            </Stack>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography variant="body2">評価</Typography>
              <Controller
                name="review"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <FormControl>
                    <Select
                      {...field}
                      error={!!error}
                      size="small"
                      displayEmpty
                      sx={{ width: 170 }}
                    >
                      <MenuItem value="">すべて</MenuItem>
                      {SEARCH_REVIEW_OPTIONS.map((option, index) => (
                        <MenuItem key={index} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
            </Stack>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography variant="body2">勤務経験</Typography>
              <Controller
                name="workResult"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <FormControl>
                    <Select
                      {...field}
                      error={!!error}
                      size="small"
                      displayEmpty
                      sx={{ width: 140 }}
                    >
                      <MenuItem value="">すべて</MenuItem>
                      {SEARCH_WORK_RESULT_OPTIONS.map((option, index) => (
                        <MenuItem key={index} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
            </Stack>
          </Stack>
          <Stack direction="row" spacing={3}>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography variant="body2">地域</Typography>
              <Controller
                name="address"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <FormControl>
                    <Select
                      {...field}
                      error={!!error}
                      size="small"
                      multiple
                      displayEmpty
                      renderValue={(selected) => {
                        return selected.length > 1 ? (
                          selected.join(',')
                        ) : (
                          <div>すべて</div>
                        );
                      }}
                      sx={{ width: 140 }}
                    >
                      {prefectures.map((option) => (
                        <MenuItem key={option.id} value={option.name}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
            </Stack>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography variant="body2">性別</Typography>
              <Controller
                name="gender"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <FormControl>
                    <Select
                      {...field}
                      error={!!error}
                      size="small"
                      displayEmpty
                      sx={{ width: 100 }}
                    >
                      <MenuItem value="">すべて</MenuItem>
                      {SEARCH_GENDER_OPTIONS.map((option, index) => (
                        <MenuItem key={index} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
            </Stack>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography variant="body2">年代</Typography>
              <Controller
                name="age"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <FormControl>
                    <Select
                      {...field}
                      error={!!error}
                      size="small"
                      displayEmpty
                      sx={{ width: 100 }}
                    >
                      <MenuItem value="">すべて</MenuItem>
                      {SEARCH_AGE_OPTIONS.map((option, index) => (
                        <MenuItem key={index} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
            </Stack>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Controller
              name="isScouted"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  {...field}
                  label={
                    <Typography variant="body2">
                      スカウト済を表示する
                    </Typography>
                  }
                  control={<Checkbox />}
                  onChange={(e, checked) => {
                    field.onChange(checked);
                  }}
                />
              )}
            />
            <LoadingButton
              type="submit"
              color="primary"
              variant="contained"
              size="small"
              loading={isLoading}
              onClick={() => fetchScouts()}
              sx={{ width: 120 }}
            >
              検索
            </LoadingButton>
          </Stack>
        </Stack>
      </Card>
      <Paper elevation={0}>
        <StandardStyleDataGrid
          columns={columns}
          rows={rows}
          rowCount={rowTotal}
          rowHeight={84}
          loading={isLoading}
          paginationModel={currentPaginationModel}
          pageSizeOptions={PAGE_SIZE_OPTIONS}
          initialState={{
            pagination: { paginationModel: INITIAL_PAGINATE_MODEL },
          }}
          hideFooter={isNotScoutPossible}
          disableColumnMenu
          onPaginationModelChange={setCurrentPaginationModel}
          sx={{
            '.MuiDataGrid-cell': {
              cursor: 'inherit',
            },
            '.MuiTablePagination-displayedRows': {
              display: 'none',
            },
          }}
        />
      </Paper>
      <ScoutInputModal
        items={selectedItem ? [selectedItem] : []}
        isOpen={isScoutInputModal}
        onClose={(item) => {
          if (item) void fetchScouts();
          setIsScoutInputModal(false);
        }}
      />
    </>
  );
}
