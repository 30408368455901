import { ReviewStats } from '@app/types/review';

export interface User {
  accessToken: string;
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  avatar: string;
  customFields: {
    addressLine4: string;
    birthday: string;
    clinicalDepartments: string[];
    confidences: string[];
    currentDepartment: string;
    currentHospital: string;
    deleted_at: string;
    familyName: string;
    familyNameKana: string;
    firstClinicalDepartments: string[];
    firstName: string;
    firstNameKana: string;
    gender: string;
    genderSelf: string;
    inviteCode?: string;
    invitedCode?: string;
    isStop?: boolean;
    jobChange?: string;
    license: string;
    medicalLicenseYear: number;
    medicalRegisterNumber: string;
    notes: string;
    orderAfterFiles: UploadFileInfo[];
    orderMatchedFiles: UploadFileInfo[];
    postalCode: string;
    universityName: string;
  };
  email: string;
  emailVerified: boolean;
  id: string;
  isLocked?: boolean;
  name: string;
  phoneNumber: string;
  reviewStats?: ReviewStats;
  typeId: string;
}

export interface UploadFileInfo {
  attachmentId: string;
  name: string;
}

export interface InvitationItemDetail {
  email: string;
  fromUserId: string;
  id: string;
  orgId: string;
  role: 'owner' | 'member';
  status: string;
}

export interface UserInfoResponse {
  customFields: {
    familyName: string;
    firstName: string;
  };
  email: string;
  id: string;
}

export interface UserScout {
  addressLine1: string;
  addressLine2: string;
  customFields: {
    birthday?: string;
    clinicalDepartments?: string[];
    confidences?: string[];
    firstClinicalDepartments?: string[];
    gender?: string;
    genderSelf?: string;
    jobChange?: string;
  };
  id: string;
  latestOrderDate?: string;
  reviewStats?: ReviewStats;
  scoutedDate?: string;
}

export const JobChange = {
  ACTIVE: 'active', // 積極的
  CONDITIONS_MATCH: 'conditionsMatch', // いい条件があれば
  NOT_THINKING: 'notThinking', // 今は考えてない
} as const;
export type JobChangeKey = (typeof JobChange)[keyof typeof JobChange];

export type InvitationForm = Omit<InvitationItemDetail, 'id'>;

export interface Follow {
  followId: string;
  followType: string;
  followerId: string;
  followerType: 'user' | 'organization' | 'product';
}

export interface FollowListResponse {
  count: number;
  total: number;
  value: Follow[];
}

export interface FollowUser {
  followType: string;
  followerId: string;
  followerType: 'user' | 'organization' | 'product';
}

export const TYPE_ID = {
  DEMAND: '000' as const,
  SUPPLY: '010' as const,
};
export type TypeIdKey = (typeof TYPE_ID)[keyof typeof TYPE_ID];

export const USER_STATUS = {
  ACTIVE: 'active' as const,
  STOP: 'stop' as const,
};
export type SupportedUserStatus =
  (typeof USER_STATUS)[keyof typeof USER_STATUS];

export const USER_STATUS_LABELS: {
  [key in SupportedUserStatus]: string;
} = {
  active: '利用中',
  stop: '利用停止',
};

export interface Attachment {
  id?: string;
  ownerId?: string;
  ownerType?: string;
  type?: string;
  url?: string;
}
