// Copyright 2022 Co-Lift Inc.
import { Backdrop, CircularProgress } from '@mui/material';
import { Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { RecoilRoot } from 'recoil';

import { DefaultLayout } from '@app/components/Layout/DefaultLayout';
import { VendorLayout } from '@app/components/Layout/VendorLayout';
import { AcceptInvitation } from '@app/views/AcceptInvitation';
import { AccountSetting } from '@app/views/AccountSetting';
import { ChatDetail } from '@app/views/ChatDetail';
import { ChatList } from '@app/views/ChatList';
import { ConfirmedUserRoute } from '@app/views/ConfirmedUserRoute';
import { DeletedOrders } from '@app/views/DeletedOrders';
import { EmailChangePage } from '@app/views/EmailChangePage';
import { Home } from '@app/views/Home';
import { LineCallback } from '@app/views/LineCallback';
import { Login } from '@app/views/Login';
import { LoginCallback } from '@app/views/LoginCallback';
import { NameChangePage } from '@app/views/NameChangePage';
import { NotFound } from '@app/views/NotFound';
import { Notification } from '@app/views/Notification';
import { OrderDetails } from '@app/views/OrderDetails';
import { Orders } from '@app/views/Orders';
import { OrganizationCreateEdit } from '@app/views/OrganizationCreateEdit';
import { OrganizationDetail } from '@app/views/OrganizationDetail';
import { OrganizationList } from '@app/views/OrganizationList';
import { OrganizationUserRoute } from '@app/views/OrganizationUserRoute';
import { PasswordChangePage } from '@app/views/PasswordChangePage';
import { PostRegistrationMessage } from '@app/views/PostRegistrationMessage';
import { ProductBulkEdit } from '@app/views/ProductBulkEdit';
import { ProductCreation } from '@app/views/ProductCreation';
import { ProductDetail } from '@app/views/ProductDetail';
import { ProductEditStatus } from '@app/views/ProductEditStatus';
import { ProductManagement } from '@app/views/ProductManagement';
import { ProductPreview } from '@app/views/ProductPreview';
import { Register } from '@app/views/Register';
import { RegistrationConfirmation } from '@app/views/RegistrationConfirmation';
import { ResendRegistrationEmail } from '@app/views/ResendRegistrationEmail';
import { ReviewLogin } from '@app/views/ReviewLogin';
import { Scouts } from '@app/views/Scouts';
import { SendResetPasswordEmail } from '@app/views/SendResetPasswordEmail';
import { TemplateCreateEdit } from '@app/views/TemplateCreateEdit';
import { Templates } from '@app/views/Templates';
import { UnconfirmedUserRoute } from '@app/views/UnconfirmedUserRoute';
import { UserDetails } from '@app/views/UserDetails';
import { Users } from '@app/views/Users';
import { WaitingForResetPassword } from '@app/views/WaitingForResetPassword';

export function App() {
  return (
    <RecoilRoot>
      <Suspense
        fallback={
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        }
      >
        <Router>
          <Routes>
            <Route path="/">
              {/* Anonymous users */}
              <Route element={<DefaultLayout />}>
                <Route path="/login" element={<Login />} />
                <Route
                  path="/login-callback/:provider"
                  element={<LoginCallback />}
                />
                <Route
                  path="/waiting-email-verify"
                  element={<PostRegistrationMessage />}
                />
                <Route path="/register">
                  <Route index element={<Register />} />
                </Route>

                <Route path="/line/callback" element={<LineCallback />} />
                <Route
                  path="/send_reset_password_email"
                  element={<SendResetPasswordEmail />}
                />
                <Route
                  path="/waiting_reset_password"
                  element={<WaitingForResetPassword />}
                />
                <Route
                  path="/accept_invitation"
                  element={<AcceptInvitation />}
                />
                <Route
                  path="/review/:orderId"
                  element={<ReviewLogin />}
                ></Route>
                <Route path="*" element={<NotFound />}></Route>
              </Route>
              {/* Unconfirmed users */}
              <Route
                element={
                  //TODO: REFACTOR SEPARATE LOGIC FROM ROUTING
                  <UnconfirmedUserRoute>
                    <DefaultLayout />
                  </UnconfirmedUserRoute>
                }
              ></Route>
              {/* Confirmed users who need to create their organization */}
              <Route
                element={
                  //TODO: REFACTOR SEPARATE LOGIC FROM ROUTING
                  <ConfirmedUserRoute>
                    <DefaultLayout />
                  </ConfirmedUserRoute>
                }
              >
                <Route
                  path="/register/organization"
                  element={<RegistrationConfirmation />}
                />
                <Route
                  path="/resend-email-verify"
                  element={<ResendRegistrationEmail />}
                />
              </Route>
              {/* Confirmed users who already have their organization */}
              <Route
                element={
                  //TODO: REFACTOR SEPARATE LOGIC FROM ROUTING
                  <OrganizationUserRoute>
                    <VendorLayout />
                  </OrganizationUserRoute>
                }
              >
                <Route index element={<Home />}></Route>
                <Route path="/home" element={<Home />}></Route>
                <Route path="/products">
                  <Route index element={<ProductManagement />} />
                  <Route path="register" element={<ProductCreation />}></Route>
                  <Route path=":productId">
                    <Route index element={<ProductDetail />} />
                    <Route path="edit" element={<ProductCreation />}></Route>
                    <Route
                      path="edit/status"
                      element={<ProductEditStatus />}
                    ></Route>
                  </Route>
                  <Route path="bulk-edit" element={<ProductBulkEdit />}></Route>
                </Route>

                {/* 医療機関 (Administratorと揃える) */}
                <Route path="/organizations">
                  <Route index element={<OrganizationList />} />
                  <Route path="register" element={<OrganizationCreateEdit />} />
                  <Route path=":orgId">
                    <Route path="" index element={<OrganizationDetail />} />
                    <Route path="edit" element={<OrganizationCreateEdit />} />
                  </Route>
                </Route>

                {/*
                    現時点で未使用
                    <Route path="/admin-info" element={<AdminInfo />}></Route> 
                    <Route path="/billing" element={<Billing />}></Route>
                    <Route path="/payment" element={<Payment />}></Route>
                  */}

                <Route path="/notification" element={<Notification />}></Route>
                <Route path="/chats" element={<ChatList />}></Route>
                <Route path="/chats/:topicId" element={<ChatDetail />}></Route>
                <Route path="/chats/templates" element={<Templates />} />
                <Route
                  path="/chats/templates/register"
                  element={<TemplateCreateEdit />}
                />
                <Route
                  path="/chats/templates/:id/edit"
                  element={<TemplateCreateEdit />}
                />
                <Route
                  path="/chats/templates/:duplicateId/copy"
                  element={<TemplateCreateEdit />}
                />
                <Route
                  path="/account-setting"
                  element={<AccountSetting />}
                ></Route>
                <Route
                  path="/account-setting/password-change"
                  element={<PasswordChangePage />}
                />
                <Route
                  path="/account-setting/name-change"
                  element={<NameChangePage />}
                />
                <Route
                  path="/account-setting/email-change"
                  element={<EmailChangePage />}
                />
                <Route path="/orders" element={<Orders />}></Route>
                <Route
                  path="/orders/deleted"
                  element={<DeletedOrders />}
                ></Route>
                <Route path="/orders/:id" element={<OrderDetails />}></Route>
                <Route path="/users" element={<Users />}></Route>
                <Route path="/users/:id" element={<UserDetails />}></Route>
                <Route path="/scouts">
                  <Route index element={<Scouts />} />
                </Route>
              </Route>
              <Route
                path="/product-preview/:productId"
                element={
                  <OrganizationUserRoute>
                    <ProductPreview />
                  </OrganizationUserRoute>
                }
              />
            </Route>
          </Routes>
        </Router>
      </Suspense>
    </RecoilRoot>
  );
}
