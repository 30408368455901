import AccountBoxIcon from '@mui/icons-material/AccountBox';
import ApartmentIcon from '@mui/icons-material/Apartment';
import ArticleIcon from '@mui/icons-material/Article';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import HomeIcon from '@mui/icons-material/Home';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import PersonIcon from '@mui/icons-material/Person';
import SettingsIcon from '@mui/icons-material/Settings';
import {
  AppBar,
  Drawer,
  Box,
  Toolbar,
  MenuList,
  MenuItem,
  Button,
  Avatar,
  Backdrop,
  CircularProgress,
  Menu,
  Snackbar,
  Alert,
  Grid,
  Badge,
  Typography,
  useTheme,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Outlet, useNavigate, useLocation, Link } from 'react-router-dom';
import { useRecoilValue, useRecoilState } from 'recoil';

import { getAllMessagesToOneUser } from '@app/adapter/chat-service';
import { getPublishedAds } from '@app/adapter/user-service';
import logo from '@app/assets/logo.svg';
import { ChatClient, attachListeners } from '@app/chatSocket';
import { Advertising } from '@app/components/Shared/Advertising';
import { CopyrightBox } from '@app/components/Shared/CopyrightBox';
import {
  loggedInUserState,
  snackbarOpenState,
  snackbarSeverityState,
  snackbarTextState,
  isLoading,
  useClearAuthStateAndStorage,
} from '@app/domain/app';
import { totalUnreadCountState } from '@app/domain/chat';
import { organization, allOrganizationsAtom } from '@app/domain/organization';
import { AppContext } from '@app/hooks/appContext';
import { useDeviceType } from '@app/hooks/useBrowserHooks';
import { Ad } from '@app/types/ad';
import { Message } from '@app/types/chats';
import { findByGroupOrDefault } from '@app/utils/ad';
import { INQUIRY_FORM_URL } from '@app/utils/constants';
import { isError } from '@app/utils/error';

const MENU_HEIGHT = 160;
const MENU_WIDTH = 280;
const MOBILE_MENU_ITEM_STYLE = {
  [`& svg`]: {
    marginLeft: 8,
    marginRight: '-2px',
  },
  display: 'flex',
  flexDirection: 'row-reverse',
};
export function VendorLayout() {
  const [isOpen, setIsOpen] = useState(false);
  const theme = useTheme();
  const { isMobile } = useDeviceType();
  const isLoadingState = useRecoilValue(isLoading);
  const loggedInUser = useRecoilValue(loggedInUserState);
  const [organizationState, setOrganizationState] =
    useRecoilState(organization);
  const allCurrentUserOrganizations = useRecoilValue(allOrganizationsAtom);

  const location = useLocation();
  const navigate = useNavigate();
  const [option, setOption] = useState('home');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const shouldOpenLogoutMenu = Boolean(anchorEl);
  const [shouldOpenSnackbar, setShouldOpenSnackbar] =
    useRecoilState(snackbarOpenState);
  const [snackbarText, setSnackbarText] = useRecoilState(snackbarTextState);
  const [snackbarSeverity, setSnackbarSeverity] = useRecoilState(
    snackbarSeverityState
  );

  const clearAuthStateAndStorage = useClearAuthStateAndStorage();

  // 未読数
  const [unreadCount, setUnreadCount] = useRecoilState(totalUnreadCountState);
  const [chatMessages, setChatMessages] = useState<Message[]>([]);

  useEffect(() => {
    if (loggedInUser) {
      if (!loggedInUser?.emailVerified) {
        navigate('/resend-email-verify');
        return;
      }
      void fetchAds();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (shouldOpenSnackbar === false) {
      setSnackbarText('');
      setSnackbarSeverity('error');
    }
  }, [shouldOpenSnackbar, setSnackbarSeverity, setSnackbarText]);

  // 未読数取得
  const fetchMessagesAndUnreadCount = useCallback(async () => {
    if (!loggedInUser?.id) {
      return;
    }
    try {
      const result = await getAllMessagesToOneUser(loggedInUser?.id);
      setChatMessages([...result.data.value].reverse());

      const unreadMessagesCount = result.data.value.filter(
        (message: Message) =>
          message.readStatus === 'unread' && message.category === 'demand'
      ).length;
      setUnreadCount(unreadMessagesCount);
    } catch (error) {
      if (isError(error)) {
        console.error(`Messageの取得に失敗しました, ${error.message}`);
      } else {
        console.error(`Messageの取得に失敗しました`);
      }
    }
  }, [loggedInUser?.id, setUnreadCount]);

  const updateChatMessages = useCallback(async () => {
    setChatMessages([...chatMessages]);
  }, [chatMessages, setChatMessages]);

  useEffect(() => {
    async function execute() {
      const chatClientInstance = new ChatClient();
      attachListeners(chatClientInstance, updateChatMessages);
      await fetchMessagesAndUnreadCount();
    }
    void execute();
    // 1分毎に実行
    const intervalId = setInterval(() => {
      void execute();
    }, 60000);

    return () => {
      clearInterval(intervalId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // クリックイベント
  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl]
  );

  const handleLogout = useCallback(() => {
    clearAuthStateAndStorage();
    navigate('/login');
  }, [navigate, clearAuthStateAndStorage]);

  const handleClickInquiry = useCallback(() => {
    window.open(INQUIRY_FORM_URL, '_blank');
  }, []);

  const handleMenuClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  // TODO: This will not have matched item when a path ends with '/'
  useEffect(() => {
    // Maybe we replace switch state with the above code to shorten this hook.
    // eg)
    // const option = location.pathname.slice(1e;)
    // setOption((old) => old === option ? old : option);
    if (location.pathname.match(/\/home\/?/)) return setOption('home');
    if (location.pathname.match(/\/products\/?/)) return setOption('product');
    if (location.pathname.match(/\/orders\/?$/)) return setOption('orders');
    if (location.pathname.match(/\/orders\/deleted\/?$/))
      return setOption('deleted-orders');
    if (location.pathname.match(/\/orders\/[a-zA-Z0-9]+\/?$/))
      return setOption('orders');
    if (location.pathname.match(/\/organizations\/?/))
      return setOption('organizations');
    if (
      location.pathname.match(/\/users\/?$/) ||
      location.pathname.match(/\/users\/[a-zA-Z0-9]+\/?$/)
    )
      return setOption('users');
    if (location.pathname.match(/\/admin-info\/?$/)) return setOption('admin');
    if (location.pathname.match(/\/billing\/?$/)) return setOption('billing');
    if (location.pathname.match(/\/payment\/?$/)) return setOption('payment');
    if (location.pathname.match(/\/notification\/?$/))
      return setOption('notification');
    if (location.pathname.match(/\/chats\/?$/)) return setOption('chat');
    if (location.pathname.match(/\/chats\/.+/)) return setOption('chat');
    if (location.pathname.match(/\/chats\/templates/)) return setOption('chat');
    if (location.pathname.match(/\/chat-detail/)) return setOption('chat');
    if (location.pathname.match(/\/scouts\/?$/)) return setOption('scout');
    if (location.pathname.match(/\/account-setting(\/.*)?$/))
      return setOption('setting');
    setOption('home');
  }, [location.pathname]);

  const onClickMenuItem = useCallback(
    (item: string) => {
      setIsOpen(false);
      switch (item) {
        case 'home':
          navigate('/home');
          break;
        case 'products':
          navigate('/products');
          break;
        case 'orders':
          navigate('/orders');
          break;
        case 'deleted-orders':
          navigate('/orders/deleted');
          break;
        case 'users':
          navigate('/users');
          break;
        case 'organizations':
          navigate('/organizations');
          break;
        case 'admin':
          navigate('/admin-info');
          break;
        case 'billing':
          navigate('/billing');
          break;
        case 'payment':
          navigate('/payment');
          break;
        case 'notification':
          navigate('/notification');
          break;
        case 'chats':
          navigate('/chats');
          break;
        case 'scout':
          navigate('/scouts');
          break;
        case 'setting':
          navigate('/account-setting');
          break;
      }
    },
    [navigate]
  );

  //
  // organization selecting
  //
  const [orgSelectorElem, setOrgSelectorElem] = useState<null | HTMLElement>(
    null
  );
  const handleClickOrgSelectButton = (
    event: React.MouseEvent<HTMLDivElement>
  ) => {
    const element = event.currentTarget;
    setOrgSelectorElem((prev) => (prev === null ? element : null));
  };
  const handleCloseOrgMenu = () => {
    setOrgSelectorElem(null);
  };
  const handleSelectOrgMenu = (event: React.MouseEvent<HTMLLIElement>) => {
    const customData = event.currentTarget.dataset;
    const selectedOrgId = customData.orgId;
    const selectedOrg = allCurrentUserOrganizations.find(
      (item) => item.id === selectedOrgId
    );
    if (selectedOrg) {
      setOrganizationState(selectedOrg);
    }
    handleCloseOrgMenu();
  };

  const adGroups = useMemo(() => {
    return ['201'];
  }, []);

  const [ads, setAds] = useState<Ad[]>([]);
  const fetchAds = useCallback(async () => {
    try {
      const result = await getPublishedAds({
        filter: { publicationGroup: adGroups },
        top: 1,
      });
      setAds(result.data.value);
    } catch (e) {
      if (isError(e)) {
        throw new Error(e.message);
      }
    }
  }, [adGroups]);

  return (
    <Box sx={{ display: 'flex' }}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 2 }}
        open={isLoadingState}
        slotProps={{
          root: {
            id: 'e2e-test-waiting-process',
          },
        }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <AppBar
        className="app-bar"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Toolbar component="div" sx={{ pl: '0 !important' }}>
          <img src={logo} className="logo" alt="Logo" />
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: 'flex' }}>
            <Grid sx={{ display: 'flex', mr: 2, mt: 2.7 }}>
              <Link
                to="/chats"
                style={{
                  color: theme.palette.text.primary,
                  display: 'flex',
                  fontSize: '1rem',
                  textDecoration: 'none',
                }}
              >
                <Badge
                  badgeContent={unreadCount}
                  color="error"
                  sx={{ mr: 0.5 }}
                >
                  <EmailOutlinedIcon />
                </Badge>
                <Typography>メッセージ</Typography>
              </Link>
            </Grid>

            {/*
             *  org selecting
             */}
            <Button
              variant="text"
              component="div"
              sx={{
                color: '#2F2F2F',
                display: { sm: 'flex', xs: 'none' },
                mt: 1,
              }}
              endIcon={<KeyboardArrowDownIcon sx={{ color: '#B0B7C3' }} />}
              onClick={handleClickOrgSelectButton}
            >
              {organizationState?.name || 'あなたの組織'}
            </Button>

            <Menu
              anchorEl={orgSelectorElem}
              open={!!orgSelectorElem}
              onClose={handleCloseOrgMenu}
            >
              {allCurrentUserOrganizations.map((item) => (
                <MenuItem
                  key={item.id}
                  data-org-id={item.id}
                  onClick={handleSelectOrgMenu}
                >
                  {item.name}
                </MenuItem>
              ))}
            </Menu>

            {/*
             * Avatar & user menu
             */}
            {loggedInUser?.avatar ? (
              <Avatar
                sx={{ ml: 2, mr: 3, mt: 1.5 }}
                src={loggedInUser?.avatar}
                onClick={(e: React.MouseEvent<HTMLDivElement>) =>
                  handleClick(e)
                }
              ></Avatar>
            ) : (
              <Avatar
                sx={{ backgroundColor: '#53BAC8', ml: 2, mr: 3, mt: 1.5 }}
                src={loggedInUser?.avatar}
                onClick={(e: React.MouseEvent<HTMLDivElement>) =>
                  handleClick(e)
                }
              >
                {loggedInUser?.customFields.firstName?.substring(0, 1) || 'あ'}
              </Avatar>
            )}
            <Menu
              anchorEl={anchorEl}
              open={shouldOpenLogoutMenu}
              onClose={handleMenuClose}
            >
              <MenuItem onClick={handleClickInquiry}>お問い合わせ</MenuItem>
              <MenuItem onClick={handleLogout}>ログアウト</MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
      {isMobile && !isOpen && (
        <IconButton
          sx={{
            flexDirection: 'column',
            fontSize: '16px',
            left: isOpen ? '245px' : '12px',
            padding: 0,
            position: 'fixed',
            top: '66px',
            transition: 'all 325ms cubic-bezier(0, 0, 0.2, 1) 100ms',
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          onClick={() => setIsOpen(!isOpen)}
        >
          <MenuIcon sx={{ height: '32px', width: '32px' }} />
          <Box display={'flex'} alignItems={'center'}>
            開く
          </Box>
        </IconButton>
      )}
      <Drawer
        variant="permanent"
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          [`& .MuiDrawer-paper`]: {
            boxSizing: 'border-box',
            marginLeft: !isMobile || isOpen ? 0 : '-226px',
            overflowX: 'hidden',
            transition: 'all 225ms cubic-bezier(0, 0, 0.2, 1) 0ms',
            width: MENU_WIDTH,
          },
          flexShrink: 0,
          width: !isMobile ? MENU_WIDTH : isOpen ? MENU_WIDTH : 0,
        }}
      >
        <Toolbar />
        <MenuList sx={{ py: '24px' }}>
          {isMobile && isOpen && (
            <MenuItem
              key={'close-menu'}
              sx={{ pb: '15px' }}
              onClick={() => setIsOpen(false)}
            >
              くメニューを閉じる
            </MenuItem>
          )}
          <MenuItem
            key={'home'}
            selected={option === 'home'}
            onClick={() => onClickMenuItem('home')}
            sx={{
              pb: '15px',
              pt: '15px',
              ...(isMobile &&
                !isOpen && {
                  mt: 6,
                  ...MOBILE_MENU_ITEM_STYLE,
                }),
            }}
          >
            <HomeIcon
              sx={{
                mr: 2,
              }}
            />
            ホーム
          </MenuItem>

          <MenuItem
            key={'product'}
            selected={option === 'product'}
            onClick={() => onClickMenuItem('products')}
            sx={{
              pb: '15px',
              pt: '15px',
              ...(isMobile &&
                !isOpen && {
                  ...MOBILE_MENU_ITEM_STYLE,
                }),
            }}
          >
            <ArticleIcon sx={{ mr: 2 }} />
            掲載管理
          </MenuItem>
          {/* 現行仕様にない機能なので削除 */}
          {/* {option === 'product' || option === 'product-drafts' ? (
            <MenuItem
              // key={'product-drafts'}
              selected={option === 'product-drafts'}
              onClick={() => onClickMenuItem('product-drafts')}
              sx={{ pb: '15px', pt: '15px' }}
              disabled
            >
              <ArticleIcon sx={{ mr: 2, visibility: 'hidden' }} />
              下書き
            </MenuItem>
          ) : undefined} */}

          <MenuItem
            key={'orders'}
            selected={option === 'orders'}
            onClick={() => onClickMenuItem('orders')}
            sx={{
              pb: '15px',
              pt: '15px',
              ...(isMobile &&
                !isOpen && {
                  ...MOBILE_MENU_ITEM_STYLE,
                }),
            }}
          >
            <AccountBoxIcon sx={{ mr: 2 }} />
            応募一覧
          </MenuItem>
          <MenuItem
            key={'users'}
            selected={option === 'users'}
            onClick={() => onClickMenuItem('users')}
            sx={{
              pb: '15px',
              pt: '15px',
              ...(isMobile &&
                !isOpen && {
                  ...MOBILE_MENU_ITEM_STYLE,
                }),
            }}
          >
            <PersonIcon sx={{ mr: 2 }} />
            フォロー一覧
          </MenuItem>
          <MenuItem
            key={'chat'}
            selected={option === 'chat'}
            onClick={() => onClickMenuItem('chats')}
            sx={{
              pb: '15px',
              pt: '15px',
              ...(isMobile &&
                !isOpen && {
                  ...MOBILE_MENU_ITEM_STYLE,
                }),
            }}
            // disabled
          >
            <MailIcon sx={{ mr: 2 }} />
            メッセージ一覧
          </MenuItem>
          <MenuItem
            key={'organizations'}
            selected={option === 'organizations'}
            onClick={() => onClickMenuItem('organizations')}
            sx={{
              pb: '15px',
              pt: '15px',
              ...(isMobile &&
                !isOpen && {
                  ...MOBILE_MENU_ITEM_STYLE,
                }),
            }}
          >
            <ApartmentIcon sx={{ mr: 2 }} />
            医療機関一覧
          </MenuItem>
          <MenuItem
            key={'scout'}
            selected={option === 'scout'}
            onClick={() => onClickMenuItem('scout')}
            sx={{ pb: '15px', pt: '15px' }}
          >
            <ArticleIcon sx={{ mr: 2 }} />
            常勤・スカウト
          </MenuItem>
          <MenuItem
            key={'setting'}
            selected={option === 'setting'}
            onClick={() => onClickMenuItem('setting')}
            sx={{
              pb: '15px',
              pt: '15px',
              ...(isMobile &&
                !isOpen && {
                  ...MOBILE_MENU_ITEM_STYLE,
                }),
            }}
          >
            <SettingsIcon sx={{ mr: 2 }} />
            アカウント設定
          </MenuItem>
        </MenuList>
        {(!isMobile || (isMobile && isOpen)) && (
          <Box textAlign="center">
            <Advertising item={findByGroupOrDefault(ads, adGroups[0])} />
          </Box>
        )}
      </Drawer>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          marginLeft: isMobile && !isOpen ? 5 : 0,
        }}
      >
        <Toolbar />
        <Box
          sx={{
            minHeight: window.innerHeight - MENU_HEIGHT,
            p: '32px',
            pb: '0',
          }}
        >
          <Snackbar
            open={shouldOpenSnackbar}
            autoHideDuration={4000}
            onClose={() => {
              setShouldOpenSnackbar(false);
            }}
            data-e2e="snackbar"
            anchorOrigin={{
              horizontal: 'center',
              vertical: 'top',
            }}
            sx={{ mt: 8, width: '50%' }}
          >
            <Alert
              onClose={() => {
                setShouldOpenSnackbar(false);
              }}
              severity={snackbarSeverity}
              sx={{ width: '100%' }}
            >
              {snackbarText}
            </Alert>
          </Snackbar>
          <AppContext.Provider
            value={{ fetchMessagesAndUnreadCount, unreadCount }}
          >
            <Outlet />
          </AppContext.Provider>
        </Box>

        <CopyrightBox />
      </Box>
    </Box>
  );
}
