import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

/**
 * Scout search form
 */
export interface ScoutSearchFormData {
  address: string[];
  age: string;
  clinicalDepartment: string[];
  gender: string;
  isScouted: number;
  jobChange: string;
  review: string;
  workResult: string;
}
export const ScoutSearchFormDefaultValues: ScoutSearchFormData = {
  address: [],
  age: '',
  clinicalDepartment: [],
  gender: '',
  isScouted: 0,
  jobChange: '',
  review: '',
  workResult: '',
};
export const ScoutSearchFormSchema = z.object({
  address: z.string().array(),
  age: z.string().optional(),
  clinicalDepartment: z.string().array(),
  gender: z.string().optional(),
  isScouted: z.number(),
  jobChange: z.string().optional(),
  review: z.string().optional(),
  workResult: z.string().optional(),
});
export const ScoutSearchForm = {
  defaultValues: ScoutSearchFormDefaultValues,
  resolver: zodResolver(ScoutSearchFormSchema),
  schema: ScoutSearchFormSchema,
} as const;

/**
 * Scout input form
 */
export interface ScoutInputFormData {
  message: string;
}
export const ScoutInputFormDefaultValues: ScoutInputFormData = {
  message: '',
};
export const ScoutInputFormSchema = z.object({
  message: z.string().min(1, { message: 'メッセージを入力してください' }),
});
export const ScoutInputForm = {
  defaultValues: ScoutInputFormDefaultValues,
  resolver: zodResolver(ScoutInputFormSchema),
  schema: ScoutInputFormSchema,
} as const;
