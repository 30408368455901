import { endOfDay, isBefore } from 'date-fns';

import { Organization } from '@app/types/organization';

export function getScoutPossibleCount(organization?: Organization): number {
  const {
    scoutMaxCount = 0,
    scoutExpirationDate,
    scoutCount = 0,
  } = organization?.customFields || {};
  const possibleCount = scoutMaxCount - scoutCount;
  // 有効期限チェック
  const isExpiration = scoutExpirationDate
    ? isBefore(new Date(), endOfDay(new Date(scoutExpirationDate)))
    : false;
  return possibleCount > 0 && isExpiration ? possibleCount : 0;
}
